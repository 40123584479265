import api from '@/api/positions'

export default {
	components: {},
	data() {
		return {
			position: null
		}
	},
	metaInfo() {
		return {
			title: this.position?.metaTitle ?? 'Position title',
			meta: [
				{ name: 'description', content: this.position?.metaDescription ?? 'Position description' },
				{ name: 'keywords', content: this.position?.metaKeywords ?? 'Position keywords' },
			]
		}
	},
	mounted() {
		if (this.positionUrl) {
			api.getByUrl(this, this.positionUrl)
				.then(response => {
					this.position = response.data[0]
				})
		}
	},
	computed: {
		positionUrl () {
			return this.$route.params.urlName
		}
	}
}
